import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { css } from '@emotion/react'
import { InfoBlock } from './components/InfoBlock'
import { useRouter } from '@/react/hooks'
import { useEffect, useState } from 'react'
import { useToolboxTitle } from '@/react/hooks/useToolboxTitle'

export const ToolboxTemplateView = () => {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const [template, setTemplate] = useState({})
  const toolboxTitle = useToolboxTitle()

  useEffect(() => {
    const getTemplateData = async () => {
      const id = stateService.params.toolbox

      const { data: tempData } = await Api.get(`toolbox_topics/${id}`, {})

      setTemplate(tempData)
    }
    getTemplateData()
  }, [])

  const { name, summary, importance, discussion, consider } = template

  return (
    <section className="toolbox-template-view" css={baseStyle}>
      <CommonPageHeader headerText={`${toolboxTitle} Templates`} />
      <ToolboxTemplatesNavBar />
      <main className="info__wrapper">
        <div className="title__block">
          <ButtonElement
            text="Back"
            functionToTrigger={() => {
              stateService.go('app.toolboxes.toolbox_templates', {
                page: stateService.params.previousPageNumber,
              })
            }}
          />
          <h1 className="page__title">{name}</h1>
        </div>
        {template?.summary && <InfoBlock title="SUMMARY" text={summary} />}
        {template?.importance && (
          <InfoBlock title="WHY IS IT IMPORTANT?" text={importance} />
        )}
        {template?.consider && (
          <InfoBlock title="THINGS TO CONSIDER" text={consider} />
        )}
        {template?.discussion && (
          <InfoBlock title="DISCUSSION ITEMS" text={discussion} />
        )}
      </main>
    </section>
  )
}

const baseStyle = css({
  paddingBottom: '250px',
  '.info__wrapper': {
    background: '#fff',
    width: '764px',
  },
  '.title__block': {
    paddingTop: '28px',
    paddingLeft: '26px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '29px',
    borderBottom: '1px solid #EEEFF3',
    gap: '10px',
  },
  '.page__title': {
    fontSize: '20px',
    margin: '0 auto',
    lineHeight: '28px',
    color: '#747678',
  },
  '@media(max-width: 1000px)': {
    '.info__wrapper': {
      width: '100%',
    },
  },
  '@media print': {
    button: {
      display: 'none',
    },
  },
})
