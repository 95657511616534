import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { useMemo } from 'react'

export const CategoriesSwitch = () => {
  const { stateService } = useRouter()
  const checked = stateService.current.name === 'app.categories.by_project'

  const { CurrentUser, $rootScope } = useAngularServices()

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const styles = getStyles(variableColor)

  return (
    <div css={styles} className="switch__wrapper">
      <h4 className="page__text">Categories</h4>
      <label className="switch">
        <input
          className="projectCheckbox"
          type="checkbox"
          checked={checked}
          onChange={() => {
            stateService.go(
              checked ? 'app.categories.list' : 'app.categories.by_project',
              { app: stateService.params.app },
              { reload: true },
            )
          }}
        />
        <span className="slider round" />
      </label>
      <h4 className="page__text">{configurableNamesList?.projects}</h4>
    </div>
  )
}

const getStyles = (variableColor) =>
  css({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '.slider': {
      border: `1px solid ${variableColor} !important`,
      backgroundColor: variableColor,
      boxShadow: 'none',
    },
    '.switch': {
      margin: 0,
      boxShadow: 'none',
    },
    '.page__text': {
      fontSize: '12px',
      fontWeight: 700,
      margin: 0,
    },
  })
