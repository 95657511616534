import * as React from 'react'
import { css } from '@emotion/react'

import { Table } from './components'
import { getSDSNavigationSettings } from '../components/commonVariables'
import {
  useAngularServices,
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
} from '@/react/components'
import { useRouter } from '@/react/hooks'

// @ts-ignore
export function SDSList() {
  const { $rootScope } = useAngularServices()
  const { stateService } = useRouter()
  const applicationId: any = stateService.params.app

  const headerText = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].safety_data_sheets_display

  return (
    <div css={css({ paddingBottom: 100 })}>
      <CommonPageHeader headerText={headerText} />
      <FloatingWindowHorizontalNavigation
        navSettings={getSDSNavigationSettings(
          'safety_data_sheets_list',
          applicationId,
        )}
      />
      <Table />
    </div>
  )
}
