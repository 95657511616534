import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'

export const useToolboxTitle = (defaultTitle?: string) => {
  const { $rootScope } = useAngularServices()
  const { stateService } = useRouter()

  const appId = Number(stateService?.params?.app)
  const app = $rootScope.mobile_apps?.find((obj) => obj.id === appId)

  return app?.toolbox || defaultTitle || ''
}
