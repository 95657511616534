import { GenericModal } from '@/react/componentAssets/GenericModal'
import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '@/assets/icons/X Gray.svg'

export const DeleteQuestionModal = ({
  setModalOpen,
  categoriesCount,
  handleDeleteAll,
  handleDelete,
}) => {
  const styles = getStyles()

  return (
    <GenericModal styles={styles} setVisible={setModalOpen}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={(e) => {
          e.stopPropagation()
          setModalOpen(false)
        }}
      />
      <div className="popup__title">{`Delete question from ${categoriesCount} categories?`}</div>
      <div className="buttons__row">
        <ButtonElement
          text="ALL CATEGORIES"
          buttonType="submit"
          additionalStyles={css({ marginBottom: '14px' })}
          functionToTrigger={() => {
            handleDeleteAll()
            setModalOpen(false)
          }}
        />
        <ButtonElement
          text="ONLY THIS CATEGORY"
          buttonType="submit"
          additionalStyles={css({ marginBottom: '14px' })}
          functionToTrigger={() => {
            handleDelete()
            setModalOpen(false)
          }}
        />
      </div>
    </GenericModal>
  )
}

const getStyles = () =>
  css({
    position: 'absolute',
    background: '#FFF',
    boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
    zIndex: 200000,
    width: '355px',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.popup__cross': {
      width: '12px',
      height: '12px',
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      paddingTop: '10px',
      paddingInline: '15px',
      paddingBottom: '20px',
    },
    '.buttons__row': {
      display: 'flex',
      gap: '10px',
      paddingInline: '20px',
      button: {
        fontSize: '11px',
      },
    },
  })
