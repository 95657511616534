import {
  AdvancedFilter,
  ButtonElement,
  CommonPageHeader,
  getFilterParam,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { NavBar } from '../commonComponents/Navbar'
import { useEffect, useState } from 'react'
import { singleHeaderTypes } from '../../components'
import { useRouter } from '@/react/hooks'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { css } from '@emotion/react'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { encodeString } from '@/utils/additionalEncodeLogic'
import { DeletedRow } from './DeletedRow'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

export const DeletedTrainings = () => {
  const [selectedItems, setSelectedItems] = useState([])

  const { stateService } = useRouter()
  const [advancedFilters, setAdvancedFilters] = useState(
    getFilterStates(stateService),
  )
  const [order, setOrder] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [isChecked, setChecked] = useState(false)
  const [listCount, setListCount] = useState(0)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isRestoreClicked, setRestoreClicked] = useState(false)
  const [documentationList, setDocumentationList] = useState([])
  const [isAllChecked, setAllChecked] = useState(false)

  const { Api, CurrentUser } = useAngularServices()
  const isSafari = checkIsSafari()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const styles = getBaseStyle(variableColor, isSafari)

  useEffect(() => {
    const role = CurrentUser.getRole()

    if (role !== 'client_admin' && role !== 'trainee_manager') {
      stateService.go('trainings.documentation', {})
    }
  }, [])

  useEffect(() => {
    const getDeletedTrainings = async () => {
      const params = {
        page: page,
        order: order,
        search: search,
        page_size: 20,
      }

      let stringParams = ''

      Object.entries(params).forEach((param) => {
        stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
      })

      Object.entries(advancedFilters).forEach((param) => {
        if (param[1].type && param[1].string) {
          stringParams +=
            getFilterParam(
              param[0],
              param[1].type,
              param[1].string,
              param[1].secondType,
              param[1].secondString,
            ) + '&'
        }
      })

      const { data } = await Api.getWithoutDeletedParamV2(
        'trainings/documentation',
        `${stringParams.slice(0, -1)}&eq(deleted,true)`,
      )

      setDocumentationList(data.results)
      setListCount(data.count)
      setRefresh(false)
    }

    getDeletedTrainings()
  }, [advancedFilters, page, order, search, refresh])

  const handleChecked = (item) => {
    const selectedIds = selectedItems.map((el) => el.id)
    if (selectedIds.includes(item.id)) {
      setSelectedItems(selectedItems.filter((el) => el.id !== item.id))
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
  }
  const handleCheckedAll = async () => {
    const selectedItems = isChecked ? [] : documentationList
    if (listCount >= 20 && selectedItems.length === documentationList.length) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
    setSelectedItems(selectedItems)
    setChecked(!isChecked)
  }

  const paginationReset = () => {
    setPage(1)
  }

  const handleRestore = async () => {
    const onlyEditableElements = selectedItems.filter((item) => item.editable)
    const v1Items = onlyEditableElements.filter(
      (item) => item.api_version === 'v1' && item.editable,
    )
    const v1Ids = v1Items.map((item) => item.id)
    const v2Items = onlyEditableElements.filter(
      (item) => item.api_version === 'v2' && item.editable,
    )
    const v2Ids = v2Items.map((item) => item.id)

    await Api.postV2WithParams(
      'trainings/documentation/bulk_restore',
      {},
      {
        ids_v1: !isAllChecked ? v1Ids.join(',') : '',
        ids_v2: !isAllChecked ? v2Ids.join(',') : '',
        ids: isAllChecked ? 'all' : '',
      },
    )

    setRefresh(true)
    setRestoreClicked(false)
    setActionsClicked(false)
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '60px',
        minWidth: '60px',
        textAlign: 'left',
        paddingLeft: '12px',
        paddingRight: '10px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingBottom: '4px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              <div
                className="action__text"
                onClick={() => {
                  setActionsClicked(false)
                  setRestoreClicked(true)
                }}
              >
                Restore
              </div>
            </div>
          )}
          {isRestoreClicked && (selectedItems.length || isChecked) && (
            <ConfirmationModal
              action="Restore"
              title={`${selectedItems.length} Trainings`}
              handleClick={handleRestore}
              handleClose={() => {
                setRestoreClicked(false)
              }}
            />
          )}
          {isRestoreClicked && !selectedItems.length && !isChecked ? (
            <div className="delete__popup">
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setRestoreClicked(false)
                  setActionsClicked(false)
                }}
              />
              <div className="popup__title">
                Please select at least one item to be deleted.
              </div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  setRestoreClicked(false)
                  setActionsClicked(false)
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      className: 'table__header trainee',
      name: 'Trainee',
      type: 'name',
      additionalStyles: {
        width: '145px',
        minWidth: '145px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'ID',
      type: 'employee_id',
      additionalStyles: {
        width: '66px',
        minWidth: '66px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'employee_id'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Topic',
      type: 'training_topic',
      additionalStyles: {
        width: '230px',
        minWidth: '230px',
        textAlign: 'left',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'training_topic'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trained',
      type: 'date_trained',
      additionalStyles: {
        minWidth: '95px',
        width: '95px',
        textAlign: 'center',
        paddingRight: '7px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
      },
    },
    {
      className: 'table__header',
      name: 'Expiring',
      type: 'date_training_expires',
      filterHeader: true,
      additionalStyles: {
        minWidth: '95px',
        width: '95px',
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Trainer',
      type: 'trained_by',
      filterHeader: true,
      additionalStyles: {
        width: '148px',
        minWidth: '148px',
        textAlign: 'center',
        paddingLeft: '0px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '45px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trained_by'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company',
      filterHeader: true,
      additionalStyles: {
        width: '178px',
        minWidth: '178px',
        textAlign: 'center',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '60px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'company'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trade',
      type: 'trade',
      filterHeader: true,
      additionalStyles: {
        width: '128px',
        minWidth: '128px',
        paddingLeft: 0,
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '49px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '5px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trade'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
  ]

  return (
    <div css={styles}>
      <CommonPageHeader headerText="Training Documentation" />
      <NavBar />
      <section className="documentation__wrapper">
        <div className="search__block">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
            onClear={() => {
              setChecked(false)
            }}
          />
        </div>
        <div className="table__container">
          <table className="documentation__table">
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {documentationList.map((documentation, index) => (
                <DeletedRow
                  handleChecked={handleChecked}
                  documentation={documentation}
                  index={index}
                  selectedItems={selectedItems}
                  search={search}
                  page={page}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={listCount}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getFilterStates(stateService) {
  return {
    name: JSON.parse(stateService.params.name || '{}'),
    company: JSON.parse(stateService.params.company || '{}'),
    training_topic: JSON.parse(stateService.params.training_topic || '{}'),
    trained_by: JSON.parse(stateService.params.trained_by || '{}'),
    employee_id: JSON.parse(stateService.params.employee_id || '{}'),
    trade: JSON.parse(stateService.params.trade || '{}'),
  }
}

function getBaseStyle(variableColor, isSafari) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    paddingBottom: '300px',
    '@media(max-width: 1365px)': {
      '.table__container': {
        overflowX: 'scroll',
        minHeight: '350px',
      },
    },
    '@media(max-width: 800px)': {
      '.delete__popup': {
        left: '80px !important',
      },
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    h2: {
      position: 'relative',
    },
    '.delete__popup': {
      position: 'absolute',
      background: '#FFF',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
      zIndex: 1,
      width: '200px',
      left: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.modal__wrapper': {
      right: '-180px !important',
    },
    '.popup__cross': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginTop: '20px',
      marginBottom: '11px',
      paddingInline: '20px',
    },
    '.cell': {
      margin: 0,
      maxHeight: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.trainee': {
      maxWidth: '135px',
    },
    '.id': {
      maxWidth: '56px',
    },
    '.topic': {
      display: 'flex',
      gap: '5px',
      maxWidth: '220px',
    },
    '.date': {
      maxWidth: '85px',
    },
    '.trained': {
      maxWidth: '138px',
    },
    '.company': {
      maxWidth: '168px',
    },
    '.trade': {
      maxWidth: '118px',
    },
    '.documentation__wrapper': {
      backgroundColor: 'white',
      maxWidth: '1180px',
      padding: '0 20px',
      marginLeft: '5px',
    },
    '.search__block': {
      paddingTop: '20px',
      paddingBottom: '15px',
    },
    '.documentation__row': {
      color: '#575757',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox__td': {
      paddingRight: '15px',
      paddingLeft: '12px',
    },
    '.link__cell': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.page-switcher-base': {
      marginBottom: '50px',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      cursor: 'pointer',
      marginLeft: '3px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '#search': {
      width: '264px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.topic__cell': {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '5px',
    },
    '.topic__text': {
      margin: 0,
      display: 'inline-block',
      maxHeight: '54px',
      maxWidth: '230px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.nav__wrapper': {
      marginLeft: '5px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.trainee__fullname': {
      left: '50px',
    },
    '.id__fullname': {
      left: '200px',
    },
    '.topic__fullname': {
      left: '270px',
    },
    '.trained__fullname': {
      left: '650px',
    },
    '.company__fullname': {
      left: '800px',
    },
    '.trade__fullname': {
      left: '890px',
    },
    '@media(max-width: 1380px)': {
      '.full__name': {
        display: 'none',
      },
    },
  })
}
