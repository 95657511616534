import * as React from 'react'
import { css } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Gray.svg'

export function SinglePhoto({ fileUrl, id, answerLink, debouncedSend }) {
  const [isDeleted, setIsDeleted] = React.useState(false)

  if (isDeleted) return null

  function deletePhoto(id) {
    answerLink.forEach((photo) => {
      if (photo.id === id) photo.deleted = true
    })
    debouncedSend([
      { field: 'photos', value: answerLink },
      { field: 'edited', value: true },
    ])
    setIsDeleted(true)
  }

  return (
    <div css={baseStyle}>
      <div className="image-holder">
        <img src={fileUrl} alt="" />
        <div
          className="delete-button"
          onClick={() => {
            deletePhoto(id)
          }}
        >
          <img src={deleteCross} />
        </div>
      </div>
    </div>
  )
}

const baseStyle = css({
  marginBottom: 20,
  width: '30%',
  height: 300,
  maxHeight: 300,
  '.image-holder': {
    position: 'relative',
    height: '100%',
    maxWidth: '100%',
    width: 'fit-content',
    marginRight: '3%',
    img: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '.delete-button': {
      cursor: 'pointer',
      position: 'absolute',
      right: 10,
      top: 10,
      borderRadius: '50%',
      backgroundColor: 'rgb(101,101,101)',
      color: 'white',
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        filter: 'brightness(1.5)',
        width: 15,
        height: 15,
      },
    },
  },
})
