import { makeLoadResolver, resolvers } from '../../app/routes/resolvers'

//All of the elements with TemplateUrl are Angular and just template - React

/** @ngInject */
export function trainingRouteConfig($stateProvider) {
  $stateProvider
    .state('trainings.program', {
      controller: function () {},
      url: '/trainings/program?pageNumber&page_size&order&reverse&search',
      template: '<training-program-page-component/>',
      resolve: makeLoadResolver('permissionViewTrainingProgram'),
    })
    .state('trainings.topics', {
      url: '/trainings/program/topics/:jobId?topicId&pageNumber&page_size&order&reverse&search',
      template: '<view-training-topic-page-component/>',
    })
    .state('trainings.preview_content', {
      url: '/trainings/program/:programId/topic/:jobId/preview_content/:contentId/?pageNumber&page_size&order&reverse&search',
      template: '<view-training-topic-content-page-component/>',
    })
    .state('trainings.trainee_quiz', {
      url: '/trainings/program/:programId/topic/:jobId/trainee_quiz/:contentId/?pageNumber&page_size&order&reverse&search',
      template: '<view-training-topic-quiz-page-component/>',
    })
    .state('share.training_documentation', {
      url: '/share/training_documentation/:key/:name/:client_id',
      template: '<share-training-documentation-page-component/>',
      controller: () => {},
      params: {
        key: {
          value: '',
          dynamic: true,
        },
        name: {
          value: '',
          dynamic: true,
        },
        client_id: {
          value: '',
          dynamic: true,
        },
      },
    })
    .state('trainings.documentation', {
      url: '/trainings/documentation?pageNumber&order&reverse&search&name&company&training_topic&trained_by&employee_id&trade',
      template: `<training-documentation-list-page/>`,
      controller: function () {},
      resolve: makeLoadResolver('permissionViewTraining'),
    })
    .state('trainings.deleted', {
      url: '/trainings/deleted?pageNumber&order&reverse&search&name&company&training_topic&trained_by&employee_id&trade',
      template: `<deleted-trainings-component />`,
      controller: function () {},
      resolve: makeLoadResolver('permissionViewTraining'),
    })
    .state('trainings.import', {
      url: '/trainings/import?previousPageNumber&previousOrder&previousReverse',
      template: `<training-bulk-upload-page/>`,
      controller: function () {},
      resolve: {
        ...resolvers.writeOnlyViewResolver,
        ...makeLoadResolver('permissionAddNewTrainingAndUploadCSV'),
      },
    })
    // .state('trainings.manage', {
    //   url: '/trainings/manage?pageNumber&page_size&order&reverse&search',
    //   templateUrl: 'app/views/training_manage.html',
    //   controller: 'TrainingListCtrl',
    //   resolve: makeLoadResolver('permissionToManageTraining'),
    // })
    .state('trainings.manage', {
      url: '/trainings/manage?pageNumber&page_size&order&reverse&search',
      template: '<training-manage-component/>',
      controller: function () {},
      resolve: makeLoadResolver('permissionToManageTraining'),
    })
    .state('trainings.add', {
      url: '/add_training?previousPageNumber&previousOrder&previousReverse',
      template: `<training-create-page/>`,
      controller: function () {},
      resolve: {
        ...resolvers.writeOnlyViewResolver,
        ...makeLoadResolver('permissionAddNewTrainingAndUploadCSV'),
      },
    })
    .state('trainings.edit', {
      url: '/trainings/:trainingID?version&previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: `<training-edit-page/>`,
      controller: function () {},
      resolve: {
        ...resolvers.writeOnlyViewResolver,
        ...makeLoadResolver('permissionAddNewTrainingAndUploadCSV'),
      },
    })
    // .state('trainings.topic', {
    //   url: '/trainings/topic/:trainingID?previousPageNumber',
    //   templateUrl: 'app/views/training_topic.html',
    //   controller: 'TrainingTopicFormCtrl',
    //   resolve: makeLoadResolver('permissionToManageTraining'),
    // })
    .state('trainings.topic', {
      url: '/trainings/topic/:trainingID?previousPageNumber',
      template: '<training-topics-component/>',
      controller: function () {},
      resolve: makeLoadResolver('permissionToManageTraining'),
    })
    .state('trainings.quiz.create', {
      url: '/training/quiz/create/:trainingID?topicId&contentId&quizName&topicOrder&previousPageNumber',
      template: '<training-quiz-create-edit-component/>',
      controller: function () {},
      resolve: makeLoadResolver('permissionToManageTraining'),
    })
    .state('trainings.quiz.edit', {
      url: '/training/quiz/edit/:contentId?trainingID&topicId&quizName&topicOrder&previousPageNumber',
      template: '<training-quiz-create-edit-component/>',
      controller: function () {},
      resolve: makeLoadResolver('permissionToManageTraining'),
    })
    // .state('trainings.assign.employees_list', {
    //   controller: 'TrainingsListCtrl',
    //   url: '/trainings/assign/employees_to_training?previousPageNumber&previousOrder&previousReverse&previousSearch',
    //   templateUrl: 'app/views/assign_employees_to_training.html',
    //   resolve: makeLoadResolver('permissionAssignTraining'),
    // })
    .state('trainings.assign.employees_list', {
      url: '/trainings/assign/employees_to_training?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<training-assign-component></training-assign-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.trainings_list', {
      controller: 'EmployeesListCtrl',
      url: '/trainings/assign/training_to_employees?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template:
        '<training-trainings-list-component></training-trainings-list-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.employee_assign', {
      url: '/trainings/assign/employees_to_training/training?previousPageNumber&previousOrder&previousReverse&previousSearch&training&trainingSet',
      template:
        '<training-employee-list-component></training-employee-list-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.training_assign', {
      url: '/trainings/assign/employees_to_training/employee?previousPageNumber&previousOrder&previousReverse&previousSearch&user&userSet',
      template:
        '<training-assign-training-list-component></training-assign-training-list-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })

    .state('trainings.assign.employee_groups_list', {
      url: '/trainings/assign/employee_groups_list?previousPageNumber?previousOrder&previousReverse&previousSearch',
      template: '<employee-groups-component />',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.employee_groups_assign', {
      url: '/trainings/assign/assign_employees_to_group/:groupID?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<assign-employees-to-group-component />',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.job_trainings_groups_assign', {
      url: '/trainings/assign/assign_jobs_to_training_group/:groupID?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template:
        '<training-groups-training-list-component></training-groups-training-list-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
    .state('trainings.assign.job_trainings_groups_list', {
      url: '/trainings/assign/job_trainings_groups_list?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template:
        '<job-training-groups-component></job-training-groups-component>',
      resolve: makeLoadResolver('permissionAssignTraining'),
    })
}
