import { TableRow } from './TableRow'

export function TableList({ rows, onVisibilityChange }: any) {
  if (rows) {
    return (
      <tbody>
        {rows.map((result) => (
          <TableRow
            key={result.id}
            result={result}
            onVisibilityChange={onVisibilityChange}
          />
        ))}
      </tbody>
    )
  } else {
    return (
      <tbody>
        <tr>
          <td>No data</td>
        </tr>
      </tbody>
    )
  }
}
