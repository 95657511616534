import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import { useEffect, useMemo, useState } from 'react'
import { SelectWithSearch } from './components/SelectWithSearch'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'

export const AddMobileReport = () => {
  const [project, setProject] = useState('')
  const [template, setTemplate] = useState('')
  const [projects, setProjects] = useState([])
  const [templates, setTemplates] = useState([])
  const [projectPage, setProjectPage] = useState(1)
  const [templatePage, setTemplatePage] = useState(0)
  const { Api, CurrentUser, $rootScope, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const configurableProjectTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).projects_display
  }, [$rootScope])

  const configurableTemplateTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).mobile_form
  }, [$rootScope])

  useEffect(() => {
    const role = CurrentUser.getRole()

    if (
      role === 'trainee' ||
      role === 'trainee_manager' ||
      role === 'read_only'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  useEffect(() => {
    const getProjectOptions = async () => {
      const clientId = CurrentUser.getClientId()

      const { data: projectsFromServer } = await Api.get('projects', {
        client: clientId,
        deleted: false,
        is_active: 'True',
        page: projectPage,
      })

      if (projectsFromServer.next) {
        setProjectPage(projectPage + 1)
      }

      setProjects((prevOptions) => [
        ...prevOptions,
        ...projectsFromServer.results,
      ])
    }
    getProjectOptions()
  }, [projectPage])

  useEffect(() => {
    const getTemplateOptions = async () => {
      const selectedProject = projects.find((p) => p.name === project)
      if (selectedProject?.id) {
        try {
          const { data: templatesFromServer } = await Api.get('mobile_forms', {
            application_id: stateService.params.app,
            project_id: selectedProject?.id,
            order: 'name',
            deleted: false,
            page: templatePage,
          })
          setTemplates((prevTemplates) => [
            ...prevTemplates,
            ...templatesFromServer.results,
          ])
          if (templatesFromServer.next) {
            setTemplatePage(templatePage + 1)
          }
        } catch {
          Notification.clearNotification()
          setTemplates([])
          setTemplatePage(1)
        }
      }
    }
    getTemplateOptions()
  }, [project, templatePage])

  const handleNextButton = () => {
    const selectedTemplate = templates.find((t) => t?.name === template)
    const selectedProject = projects.find((p) => p.name === project)

    setButtonDisabled(true)
    stateService.go('app.forms.reports_create', {
      form: selectedTemplate?.id,
      project: selectedProject?.id,
    })
  }

  const handleTemplateReset = () => {
    setTemplates([])
    setTemplatePage(1)
    setTemplate('')
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={`${configurableTemplateTitle} Reports`} />
      <MobileFormsNavBar />
      <main>
        <div className="top__section">
          <h4 className="section__header">Submit Report</h4>
          <ButtonElement
            text="Next"
            disabled={!template}
            functionToTrigger={handleNextButton}
            buttonType="submit"
          />
        </div>
        <div className="select__section">
          <SelectWithSearch
            value={project}
            setValue={setProject}
            options={projects}
            title={configurableProjectTitle}
            resetFunction={handleTemplateReset}
          />
          <SelectWithSearch
            value={template}
            setValue={setTemplate}
            options={templates}
            title={configurableTemplateTitle}
            disabled={!templates.length || isButtonDisabled}
          />
        </div>
      </main>
    </section>
  )
}

const baseStyle = css({
  main: {
    background: '#fff',
    width: '835px',
  },
  '.top__section': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
    paddingInline: '26px',
    paddingBottom: '12px',
    borderBottom: '1px solid #EEEFF3',
  },
  '.select__section': {
    display: 'flex',
    gap: '20px',
    paddingTop: '21px',
    paddingInline: '26px',
    paddingBottom: '50px',
  },
  '.select___title': {
    color: '#575757',
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  '@media(max-width: 1080px)': {
    main: {
      width: '100%',
    },
    '.select__section': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})
