import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { useEffect, useState } from 'react'
import { singleHeaderTypes } from '../../components'
import { css } from '@emotion/react'
import { GroupRow } from './components/GroupRow'
import { useRouter } from '@/react/hooks'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { TableInput } from '@/react/componentAssets/TableInput'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import { useToolboxTitle } from '@/react/hooks/useToolboxTitle'
export const ToolboxTalkGroups = () => {
  const [search, setSearch] = useState('')
  const [groupsList, setGroupsList] = useState([])
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('name')
  const [count, setCount] = useState(20)
  const [isAddClicked, setAddClicked] = useState(false)

  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const toolboxTitle = useToolboxTitle()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isSafari = checkIsSafari()
  const styles = getStyles(variableColor, isSafari)

  useEffect(() => {
    const getToolboxGroups = async () => {
      const { data } = await Api.get('toolbox_topic_sets', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        deleted: false,
        order,
        search,
        page,
      })

      setCount(data.count)

      setGroupsList(data?.results)
      setRefresh(false)
    }

    getToolboxGroups()
  }, [refresh, order, search, page])

  useEffect(() => {
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [stateService.params])

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'topic',
      name: 'Group Name',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        maxWidth: '441px',
        width: '441px',
        minWidth: '441px',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          paddingLeft: '10px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          width: 'fit-content',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
        '.add__button': {
          color: variableColor,
          fontWeight: 400,
        },
      },
      additionalInnerElement: (
        <div className="add__wrapper">
          <p
            className="add__button"
            onClick={() => {
              setAddClicked(true)
            }}
          >
            + Group
          </p>
        </div>
      ),
    },
    {
      className: 'originator',
      name: 'Templates',
      type: 'user__first_name,user__last_name',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '100px',
        minWidth: '100px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    {
      className: 'originator',
      name: 'Default',
      type: 'is_default',
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '100px',
        minWidth: '100px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
      additionalInnerElement: (
        <div className="info__wrapper">
          <InfoIcon
            handleClick={() => {
              setInfoModalVisible(true)
            }}
          />
          {isInfoModalVisible && (
            <InfoModal
              setModalOpen={setInfoModalVisible}
              message="A default group will automatically assign templates to all existing and future projects."
              right="-50px"
            />
          )}
        </div>
      ),
    },
    {
      className: 'originator',
      name: 'Delete',
      type: 'user__first_name,user__last_name',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '100px',
        minWidth: '100px',
        textAlign: 'left',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  ]

  const handleGroupAdd = async (name: string) => {
    await Api.post('toolbox_topic_sets', {
      client: CurrentUser.getClientId(),
      application: stateService.params.app,
      name,
    })

    setRefresh(true)
  }

  const defaultId = groupsList?.find((group) => group?.is_default)?.id

  return (
    <section css={styles}>
      <CommonPageHeader headerText={`${toolboxTitle} Templates`} />
      <ToolboxTemplatesNavBar />
      <main className="groups__wrapper">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
        />
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              setOrder={setOrder}
              order={order}
              updateUrl={false}
              popUpLocation={false}
              callback={setOrder}
            />
            <tbody>
              {isAddClicked && (
                <tr>
                  <td>
                    <TableInput
                      defaultValue=""
                      handleSubmit={handleGroupAdd}
                      handleClose={() => {
                        setAddClicked(false)
                      }}
                      inputWidth="350px"
                    />
                  </td>
                </tr>
              )}
              {groupsList?.length
                ? groupsList?.map((group, index) => (
                    <GroupRow
                      key={group?.id}
                      group={group}
                      index={index}
                      search={search}
                      page={page}
                      defaultId={defaultId}
                      setRefresh={setRefresh}
                    />
                  ))
                : null}
            </tbody>
          </table>
          <div className="switch__wrapper">
            <PageSwitcher
              pageLength={20}
              listCount={count}
              currentPage={page}
              callback={setPage}
              updateUrl={false}
            />
          </div>
        </div>
      </main>
    </section>
  )
}

const getStyles = (variableColor, isSafari) =>
  css({
    paddingBottom: '250px',
    '.groups__wrapper': {
      background: '#fff',
      marginLeft: '5px',
      padding: '20px',
      width: 'fit-content',
    },
    '#search': {
      width: '264px',
    },
    '.table__wrapper': {
      paddingTop: '20px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.info__wrapper': {
      height: '21px',
      display: 'flex',
      alignItems: 'flex-start',
      position: 'relative',
    },
    tr: {
      height: '30px',
    },
    td: {
      height: '40px',
    },
    svg: {
      cursor: 'pointer',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.title__td': {
      paddingLeft: '15px',
    },
    '.title__text': {
      width: 'fit-content',
      margin: 0,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#3980CE',
      maxWidth: '426px',
    },
    '.topic__info': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
    },
    '.topic__count': {
      width: '10px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.checkmark__td': {
      display: 'flex',
      justifyContent: 'center',
      height: '32px',
      alignItems: 'center',
      position: 'relative',
    },
    '.icon__td': {
      textAlign: 'center',
      position: 'relative',
    },
    input: {
      left: '0 !important',
    },
    '.checkbox-container': {
      width: '22px',
      paddingLeft: '10px',
      marginBottom: 0,
    },
    '.input__wrapper': {
      paddingLeft: '10px',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      marginTop: '30px',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '50px',
      padding: '4px 11px',
      maxWidth: '500px',
    },
    '@media(max-width: 1000px)': {
      '.groups__wrapper': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
