import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'
import { GenericModal } from './GenericModal'

export const InfoModal = ({
  setModalOpen,
  message,
  top = '-55px',
  left = 'auto',
  right = 'auto',
  bottom = 'auto',
}) => {
  const styles = getStyles(top, left, right, bottom)

  return (
    <GenericModal styles={styles} setVisible={setModalOpen}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={(e) => {
          e.stopPropagation()
          setModalOpen(false)
        }}
      />
      <div className="popup__title">{message}</div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '14px' })}
        functionToTrigger={() => {
          setModalOpen(false)
        }}
      />
    </GenericModal>
  )
}

const getStyles = (top, left, right, bottom) =>
  css({
    position: 'absolute',
    background: '#FFF',
    boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
    zIndex: 200000,
    top: top,
    left: left,
    right: right,
    bottom: bottom,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '300px',
    maxWidth: '320px',
    '.popup__cross': {
      width: '12px',
      height: '12px',
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      paddingTop: '20px',
      paddingInline: '20px',
      paddingBottom: '20px',
    },
  })
