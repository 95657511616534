import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  ButtonElement,
  CommonPageHeader,
  TextInput,
  useAngularServices,
} from '@/react/components'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { useToolboxTitle } from '@/react/hooks/useToolboxTitle'

export const CreatePage = () => {
  const { $rootScope, Api, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const app = $rootScope.mobile_apps.find((obj) => {
    return obj.id === Number(stateService.params.app)
  })

  const toolboxTitle = useToolboxTitle()

  const isEditing = Boolean(stateService.params.toolbox)
  const [toolboxData, setToolboxData] = useState({
    name: '',
    summary: '',
    importance: '',
    consider: '',
    discussion: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [toolboxDataPrev, setToolboxDataPrev] = useState({})

  useEffect(() => {
    if (isEditing) {
      Api.ToolboxTopics.byID(stateService.params.toolbox, (resp) => {
        setToolboxData(resp)
        setToolboxDataPrev(resp)
      })
    }
  }, [])

  const handleSubmit = async () => {
    setIsSubmitting(true)

    if (toolboxData.name?.length && toolboxData.summary?.length) {
      try {
        if (isEditing) {
          if (isEqual(toolboxData, toolboxDataPrev)) {
            Notification.danger('Please edit at least one field before saving.')
            return
          }
          await Api.ToolboxTopics.patch(toolboxData)
        } else {
          await Api.ToolboxTopics.post({ ...toolboxData, application: app.id })
        }

        const action = isEditing ? 'Updated!' : 'Created!'
        Notification.success('Toolbox Topic Successfully ' + action)

        if (stateService?.params.previousSearch === 'fromTemplates') {
          stateService.go('app.toolboxes.toolbox_templates')
        } else {
          stateService.go('app.toolboxes.project_to_toolbox', {
            app: $rootScope.$state.params.app,
            pageNumber: $rootScope.$state.params.previousPageNumber,
            order: $rootScope.$state.params.previousOrder,
            reverse: $rootScope.$state.params.previousReverse,
            search: $rootScope.$state.params.previousSearch,
          })
        }
      } catch (error) {
        console.error(isEditing ? 'Failed to modify' : 'Failed to create')
      }
    }
  }

  const isSaveBtnDisabled = () => {
    return (
      !toolboxData.name?.length ||
      !toolboxData.summary?.length ||
      isEqual(toolboxData, toolboxDataPrev) ||
      isSubmitting
    )
  }

  useEffect(() => {
    setIsSubmitting(false) // make button active on each change
  }, [toolboxData])

  const isMobile = window.innerWidth <= 536

  return (
    <section>
      <CommonPageHeader headerText={`${toolboxTitle} Templates`} />
      <ToolboxTemplatesNavBar />
      <main css={baseStyle}>
        <div className="create-page-base">
          {(!isEditing || (isEditing && toolboxDataPrev.name?.length)) && (
            <div className="form-wrapper">
              {isMobile ? (
                <div className="top__row--m">
                  <div className="buttons__wrapper--m">
                    {isEditing && (
                      <div className="button-wrapper">
                        <ButtonElement
                          text="Back"
                          width="75px"
                          functionToTrigger={() => {
                            stateService.go('app.toolboxes.toolbox_templates', {
                              page: stateService.params.previousPageNumber,
                            })
                          }}
                        />
                      </div>
                    )}
                    <div className="button-wrapper-save">
                      <ButtonElement
                        text="Save"
                        width="75px"
                        buttonType="submit"
                        additionalStyles={css({
                          ':disabled': {
                            color: '#fff!important',
                            borderColor: '#888!important',
                            backgroundColor: '#888!important',
                          },
                        })}
                        disabled={isSaveBtnDisabled()}
                        functionToTrigger={handleSubmit}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames('common-field title-field', {
                      'error-field': !toolboxData.name.length,
                    })}
                  >
                    <label htmlFor="newToolboxTalksName">
                      {
                        $rootScope.mobile_apps.filter((obj) => {
                          return obj.id === Number(stateService.params.app)
                        })[0].toolbox
                      }{' '}
                      Name
                      <span style={{ color: '#FF0A0A' }}>
                        <sup>*</sup>
                      </span>
                    </label>
                    <TextInput
                      height={30}
                      defaultValue={toolboxData.name}
                      valueCallback={(value) =>
                        setToolboxData({ ...toolboxData, name: value })
                      }
                      textarea
                      allowEmptyStart={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="top__row">
                  {isEditing ? (
                    <div className="button-wrapper">
                      <ButtonElement
                        text="Back"
                        width="75px"
                        functionToTrigger={() => {
                          stateService.go('app.toolboxes.toolbox_templates', {
                            page: stateService.params.previousPageNumber,
                          })
                        }}
                      />
                    </div>
                  ) : null}
                  <div
                    className={classNames('common-field title-field', {
                      'error-field': !toolboxData.name.length,
                    })}
                  >
                    <label htmlFor="newToolboxTalksName">
                      {
                        $rootScope.mobile_apps.filter((obj) => {
                          return obj.id === Number(stateService.params.app)
                        })[0].toolbox
                      }{' '}
                      Name
                      <span style={{ color: '#FF0A0A' }}>
                        <sup>*</sup>
                      </span>
                    </label>
                    <TextInput
                      height={30}
                      defaultValue={toolboxData.name}
                      valueCallback={(value) =>
                        setToolboxData({ ...toolboxData, name: value })
                      }
                      textarea
                      allowEmptyStart={false}
                    />
                  </div>
                  <div className="button-wrapper">
                    <ButtonElement
                      text="Save"
                      width="75px"
                      buttonType="submit"
                      additionalStyles={css({
                        ':disabled': {
                          color: '#fff!important',
                          borderColor: '#888!important',
                          backgroundColor: '#888!important',
                        },
                      })}
                      disabled={isSaveBtnDisabled()}
                      functionToTrigger={handleSubmit}
                    />
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  'summary-field common-field description-field',
                  {
                    'error-field': !toolboxData.summary.length,
                  },
                )}
              >
                <label htmlFor="summary">
                  Summary
                  <span style={{ color: '#FF0A0A' }}>
                    <sup>*</sup>
                  </span>
                </label>
                <TextInput
                  defaultValue={toolboxData.summary}
                  valueCallback={(value) =>
                    setToolboxData({ ...toolboxData, summary: value })
                  }
                  textarea
                  allowEmptyStart={false}
                />
              </div>
              <div className="why-important-field common-field description-field">
                <label htmlFor="important">Why is it important?</label>
                <TextInput
                  defaultValue={toolboxData.importance}
                  valueCallback={(value) =>
                    setToolboxData({ ...toolboxData, importance: value })
                  }
                  textarea
                />
              </div>
              <div className="things-consider-field common-field description-field">
                <label htmlFor="consider">Things to consider</label>
                <TextInput
                  defaultValue={toolboxData.consider}
                  valueCallback={(value) =>
                    setToolboxData({ ...toolboxData, consider: value })
                  }
                  textarea
                />
              </div>
              <div className="discuss-field common-field description-field">
                <label htmlFor="discussion">Discussion Items</label>
                <TextInput
                  defaultValue={toolboxData.discussion}
                  valueCallback={(value) =>
                    setToolboxData({ ...toolboxData, discussion: value })
                  }
                  textarea
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  marginLeft: '5px',
  marginTop: -1,
  position: 'relative',
  '.create-page-base': {
    padding: '25px',
    backgroundColor: 'white',
    marginBottom: '90px',
  },
  '.top__row': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.common-field': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '14px',
    fontWeight: 700,
    color: '#747678',
    label: {
      marginBottom: '3px',
    },
  },
  '.description-field': {
    textarea: {
      width: '100%',
      height: '30px',
      border: '1px solid #ececec',
      outline: 'none',
      padding: '4px 5px',
      fontSize: '14px',
      fontWeight: 400,
      minHeight: '65px',
      overflow: 'hidden',
      resize: 'none',
    },
  },
  '.title-field': {
    alignItems: 'center',
    maxWidth: '400px',
    margin: '0 auto',
    div: {
      width: '100%',
    },
    textarea: {
      width: '100%',
      border: '1px solid #ececec',
      outline: 'none',
      padding: '6px 7px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 400,
      overflow: 'hidden',
      resize: 'none',
    },
  },
  '.summary-field': {
    marginTop: '18px',
  },
  '.error-field': {
    input: {
      borderColor: '#FF0A0A',
    },
    textarea: {
      borderColor: '#FF0A0A',
    },
  },
  '.why-important-field': {
    marginTop: '18px',
  },
  '.things-consider-field': {
    marginTop: '18px',
  },
  '.discuss-field': {
    marginTop: '18px',
  },
  '.top__row--m': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  '.buttons__wrapper--m': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.button-wrapper-save': {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
})
