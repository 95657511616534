// import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function loginPagesConfig($stateProvider) {
  $stateProvider
    .state('login', {
      url: '/login',
      template: '<login-page-redirect />',
      controller: function () {},
    })
    .state('reset', {
      url: '/reset?token',
      template: '<forgot-password-redirect />',
      controller: function () {},
    })
    .state('invite', {
      url: '/invite?token',
      template: '<invite-user-redirect />',
      controller: function () {},
    })

  //TODO: comment by task 304

  // .state('request', {
  //   url: '/request-invite',
  //   template: '<request-invite />',
  //   controller: function () {},
  // })
}
