import React, { useEffect, useRef, useState } from 'react'
import { getToolboxTalksNavigationData } from '../components/commonVariables'
import {
  AdvancedFilter,
  ButtonElement,
  CommonPageHeader,
  FloatingWindowHorizontalNavigation,
  PageSwitcher,
  Search,
  TableHeaders,
  getFilterParam,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { singleHeaderTypes } from '../../components'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { ToolboxRow } from './components/ToolboxRow'
import { DownloadModal } from './components/DownloadModal'
import { encodeString } from '@/utils/additionalEncodeLogic'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { ToolboxTalksNavBar } from './ToolboxTalksNavBar'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { AdvancedDateFilter } from '@/react/components/AdvancedDateFilter'

export const ToolboxTalkList = () => {
  const { stateService } = useRouter()

  const [toolboxList, setToolboxList] = useState([])
  const [isAnalyticsIconVisible, setAnalyticsIconVisible] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [advancedFilters, setAdvancedFilters] = useState(
    getFilterStates(stateService),
  )
  const { Api, CurrentUser, Notification, $rootScope } = useAngularServices()

  const [isAllChecked, setAllChecked] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isDeleteButtonClicked, setDeleteButtonClicked] = useState(false)
  const [isDeleteActionAvailable, setDeleteActionAvailable] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [isDownloadModalVisible, setDownloadModalVisible] = useState(false)
  const [count, setCount] = useState(20)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const variableTitle = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].toolbox_talks_display

  const variableSingular = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].toolbox

  const variableProjectHeader = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].projects_display

  const popupRef = useRef()

  useEffect(() => {
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [stateService.params])

  useEffect(() => {
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [advancedFilters])

  useEffect(() => {
    const role = CurrentUser.getRole()
    if (role === 'client_admin') {
      setAnalyticsIconVisible(true)
      setDeleteActionAvailable(true)
    }
    if (
      role === 'read_only' ||
      role === 'trainee_manager' ||
      role === 'trainee'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  useEffect(() => {
    const getViewToolboxes = async () => {
      const params = {
        application: stateService.params.app,
        deleted: false,
        is_active: true,
        order: order,
        page: page,
        search: search,
      }

      let stringParams = ''

      Object.entries(params).forEach((param) => {
        stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
      })

      Object.entries(advancedFilters).forEach((param) => {
        if (param[1].type && param[1].string) {
          stringParams +=
            getFilterParam(
              param[0],
              param[1].type,
              param[1].string,
              param[1].secondType,
              param[1].secondString,
            ) + '&'
        }
      })

      const { data } = await Api.get(
        'toolbox_talks/display_list',
        stringParams.slice(0, -1),
      )

      setToolboxList(data.results)
      setCount(data.count)
      if (isAllChecked) {
        const toolboxIds = data.results.map((toolbox) => toolbox?.id)
        setSelectedIds(toolboxIds)
      }
      setButtonDisabled(false)
      setRefresh(false)
    }
    getViewToolboxes()
  }, [page, order, search, advancedFilters, refresh])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDeleteButtonClicked(false)
      }
    }

    if (isDeleteButtonClicked) {
      setDeleteButtonClicked(true)
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isDeleteButtonClicked])

  const handleChecked = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id))
      setAllChecked(false)
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const handleCheckedAll = () => {
    const toolboxIds = toolboxList.map((toolbox) => toolbox?.id)
    const selectedToSet = !isAllChecked ? toolboxIds : []
    setAllChecked(!isAllChecked)
    setSelectedIds(selectedToSet)
  }

  const handleDelete = async () => {
    setPage(1)
    setButtonDisabled(true)

    await Api.delete(`toolbox_talks/bulk_delete`, {
      ids: isAllChecked ? 'all' : selectedIds.join(','),
      search,
      is_draft: false,
      non_empty: true,
    })
    setAllChecked(false)
    setSelectedIds([])
    setRefresh(true)
    setDeleteButtonClicked(false)
  }

  const paginationReset = () => {
    setPage(1)
  }

  const downloadFile = (fileUrl: string, fileName: string) => {
    const anchor = document.createElement('a')
    anchor.href = fileUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
    setDownloadModalVisible(false)
  }

  const handleExportPDF = async () => {
    setActionsClicked(false)
    setDownloadModalVisible(true)
    if (selectedIds.length === 1) {
      try {
        const { data: createFileResponse } = await Api.get(
          `toolbox_talks/${selectedIds[0]}/export`,
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.file_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Toolboxes')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time * 1000)
      } catch {
        Notification.warning('Failed to get file url for download')
      }
    } else {
      try {
        const { data: createFileResponse } = await Api.post(
          'toolbox_talks/export_multiple',
          {
            ids: selectedIds,
          },
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.file_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Toolboxes')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time * 1000)
      } catch {
        Notification.warning('Failed to get file url for download')
      }
    }
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '55px',
        minWidth: '55px',
        textAlign: 'left',
        paddingRight: '10px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '12px',
        height: '30px',
        paddingBottom: '4px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isDeleteActionAvailable && selectedIds.length ? (
                <div
                  className="action__text"
                  onClick={() => {
                    setActionsClicked(false)
                    setDeleteButtonClicked(true)
                  }}
                >
                  Delete
                </div>
              ) : null}
              {selectedIds.length ? (
                <div
                  className="action__text dark"
                  onClick={() => {
                    handleExportPDF()
                  }}
                >
                  Export PDF
                </div>
              ) : null}
            </div>
          )}
          {isDeleteButtonClicked && selectedIds.length ? (
            <div className="delete__popup" ref={popupRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setDeleteButtonClicked(false)
                }}
              />
              <div className="popup__title">
                <strong>Delete</strong>
                {`${isAllChecked ? count : selectedIds.length} ${
                  selectedIds.length > 1 ? variableTitle : variableSingular
                }?`}
              </div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '20px' })}
                disabled={isButtonDisabled}
                functionToTrigger={() => {
                  handleDelete()
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      className: 'topic',
      name: 'Topic',
      type: 'topic__name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: '314px',
        minWidth: '314px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: 0,
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'topic__name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'originator',
      name: 'Originator',
      type: 'user__first_name,user__last_name',
      filterHeader: true,
      additionalStyles: {
        paddingLeft: '0px',
        width: '169px',
        minWidth: '169px',
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'company',
      name: 'Company',
      type: 'user__company_name',
      filterHeader: true,
      additionalStyles: {
        paddingLeft: '0px',
        width: '188px',
        minWidth: '188px',
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user__company_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'project',
      name: variableProjectHeader,
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        paddingLeft: '0px',
        width: '175px',
        minWidth: '175px',
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'project__name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'completed',
      name: 'Completed',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        paddingLeft: '0px',
        width: '103px',
        minWidth: '103px',
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedDateFilter
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      ),
    },
    {
      className: 'share',
      name: 'Share',
      type: '',
      filterHeader: true,
      additionalStyles: {
        paddingLeft: '0px',
        width: '76px',
        minWidth: '76px',
        textAlign: 'center',
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  ]

  const isSafari = checkIsSafari()

  const baseStyle = getbaseStyle(variableColor, isSafari)

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={variableTitle}
        isAnalyticsIconVisible={isAnalyticsIconVisible}
        iconLink="https://rtrspro.knowledgetransfer.us/#/workbooks/11/views"
      />
      {isDownloadModalVisible && (
        <DownloadModal
          setModalVisible={setDownloadModalVisible}
          selectedIds={selectedIds}
        />
      )}
      <ToolboxTalksNavBar />
      <div className="toolbox__wrapper">
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={setPage}
            updateUrl={false}
            onClear={() => {
              setAllChecked(false)
            }}
          />
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {toolboxList.map((toolbox, index) => (
                <ToolboxRow
                  key={toolbox?.id}
                  toolbox={toolbox}
                  index={index}
                  handleChecked={handleChecked}
                  selectedIds={selectedIds}
                  search={search}
                  page={page}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

function getbaseStyle(variableColor, isSafari) {
  return css({
    paddingBottom: '220px',
    '.alert-block': {
      right: '-90px !important',
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.text__cell': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '105px',
      whiteSpace: 'nowrap',
    },
    '.topic__cell': {
      color: '#3980CE',

      span: {
        cursor: 'pointer',
      },
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.topic': {
      left: '50px',
    },
    '.company': {
      left: '500px',
    },
    '.project': {
      left: '650px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.toolbox__wrapper': {
      background: 'white',
      marginLeft: '5px',
      width: '1120px',
      padding: '0 20px',
    },
    '.table__wrapper': {
      minHeight: '400px',
    },
    '.search__wrapper': {
      paddingTop: '20px',
      paddingBottom: '15px',
    },
    '.delete__popup': {
      position: 'absolute',
      background: '#FFF',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
      zIndex: 1,
      width: '200px',
      left: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.popup__cross': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      display: 'flex',
      flexDirection: 'column',
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '-0.14px',
      marginTop: '10px',
      marginBottom: '11px',
      paddingInline: '20px',
    },
    '.cell': {
      margin: 0,
      maxHeight: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox__td': {
      paddingRight: '15px',
      paddingLeft: '12px',
    },
    '.link__cell': {
      display: 'flex',
      alignItems: 'center',
      color: '#3980CE',
      cursor: 'pointer',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      paddingLeft: '25px',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '12px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      cursor: 'pointer',
      marginLeft: '3px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '#search': {
      width: '264px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.share__td': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      svg: {
        cursor: 'pointer',
      },
    },
    '.sucess__copy': {
      display: 'flex',
      gap: '4px',
      background: ' #FFF',
      paddingInline: '9px',
      paddingTop: '5px',
      paddingBottom: '5px',
      width: '153px',
      position: 'absolute',

      right: '45px',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.25)',
    },
    '.copy__message': {
      fontSize: '10px',
      margin: 0,
      fontWeight: 600,
      color: '#68696D',
    },
    '@media(max-width: 1350px)': {
      '.toolbox__wrapper': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
      '.full__name': {
        display: 'none',
      },
    },
    '@media(max-width: 800px)': {
      '.delete__popup': {
        left: '100px',
      },
    },
  })
}

function getFilterStates(stateService) {
  return {
    topic__name: JSON.parse(stateService.params.topic__name || '{}'),
    user_full_name: JSON.parse(stateService.user_full_name || '{}'),
    user__company_name: JSON.parse(
      stateService.params.user__company_name || '{}',
    ),
    project__name: JSON.parse(stateService.params.project__name || '{}'),
  }
}
