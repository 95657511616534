import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { useEffect, useState } from 'react'
import { singleHeaderTypes } from '../../components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { TemplateRow } from './components/TemplateRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { useToolboxTitle } from '@/react/hooks/useToolboxTitle'

export const ToolboxTalksTemplates = () => {
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('name')
  const [page, setPage] = useState(1)
  const [templateList, setTemplateList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const { stateService } = useRouter()
  const { Api, CurrentUser } = useAngularServices()
  const toolboxTitle = useToolboxTitle()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isSupervisor = CurrentUser.getRole() === 'supervisor'

  useEffect(() => {
    if (stateService.params.page) {
      setPage(+stateService.params.page)
      setTimeout(() => {
        setRefresh(true)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    const getTemplates = async () => {
      const { data } = await Api.get('toolbox_topics', {
        application: stateService.params.app,
        is_active: true,
        order,
        page,
        search,
      })

      setTemplateList(data)
      setRefresh(false)
    }
    getTemplates()
  }, [order, page, search, refresh])

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'topic',
      name: 'Topic',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: '526px',
        minWidth: '526px',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'originator',
      name: 'Edit',
      type: 'user__first_name,user__last_name',
      filterHeader: false,
      additionalStyles: {
        paddingLeft: '0px',
        width: '60px',
        minWidth: '60px',
        textAlign: 'center',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    {
      className: 'originator',
      name: 'View',
      type: 'user__first_name,user__last_name',
      filterHeader: false,
      additionalStyles: {
        paddingLeft: '0px',
        width: '60px',
        minWidth: '60px',
        textAlign: 'center',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    {
      className: 'originator',
      name: 'Delete',
      type: 'user__first_name,user__last_name',
      filterHeader: false,
      additionalStyles: {
        paddingLeft: '0px',
        width: '60px',
        minWidth: '60px',
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  ]

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)

  return (
    <section className="toolbox-templates" css={baseStyle}>
      <CommonPageHeader headerText={`${toolboxTitle} Templates`} />
      <ToolboxTemplatesNavBar />
      <main className="content__wrapper">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
        />
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={
                !isSupervisor
                  ? TABLE_HEADER_SETTINGS
                  : TABLE_HEADER_SETTINGS.filter(
                      (option) =>
                        option.name !== 'Edit' && option?.name !== 'Delete',
                    )
              }
              setOrder={setOrder}
              order={order}
              updateUrl={false}
              popUpLocation={false}
              callback={setOrder}
            />
            <tbody>
              {templateList?.results?.map((template, index) => (
                <TemplateRow
                  template={template}
                  index={index}
                  search={search}
                  setRefresh={setRefresh}
                  isSupervisor={isSupervisor}
                  page={page}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="switcher__block">
          <PageSwitcher
            pageLength={20}
            listCount={templateList?.count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </section>
  )
}

const getBaseStyle = (variableColor, isSafari) =>
  css({
    paddingBottom: '350px',
    '.content__wrapper': {
      background: '#fff',
      width: 'fit-content',
      paddingTop: '20px',
      paddingInline: '23px',
      marginLeft: '5px',
    },
    '.table__row': {
      color: '#575757',
      height: '38px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.icon__td': {
      textAlign: 'center',
    },
    '.switcher__block': {
      paddingBottom: '20px',
    },
    '.text__cell': {
      maxWidth: '500px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      margin: 0,
      paddingLeft: '15px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
      position: 'relative',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
      whiteSpace: 'pre-wrap',
    },
    '@media(max-width: 970px)': {
      '.content__wrapper': {
        width: 'auto',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
