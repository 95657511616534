import React, { useState, useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import {
  ButtonElement,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { TableBody } from '@screens/ToolboxTalks/DeletedListPage/components/Table/components/TableBody'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import { singleHeaderTypes } from '@screens/components'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const Table = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [count, setCount] = useState<number>(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = useState('')
  const [isAllChecked, setAllChecked] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [deletedList, setDeletedList] = useState([])
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isRestoreClicked, setRestoreClicked] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const restoreRef = useRef(null)
  const restorePopUpRef = useRef(null)

  useEffect(() => {
    let isMounted = true

    const getDeletedToolboxes = async () => {
      const { data: result } = await Api.get(`toolbox_talks/display_list`, {
        page,
        search,
        order,
        application: stateService.params.app,
        is_active: 'true',
        deleted: 'true',
        role: true,
        table: true,
        tz_offset: new Date().getTimezoneOffset(),
      })

      if (isAllChecked) {
        setSelectedItems(result.results)
      }
      if (isMounted) {
        setCount(result.count)
        setDeletedList(result.results)
      }
      setRefresh(false)
    }

    getDeletedToolboxes()

    return () => {
      isMounted = false
    }
  }, [page, search, order, refresh])

  const closeDropdown = (event) => {
    if (restoreRef.current && !restoreRef.current.contains(event.target)) {
      setActionsClicked(false)
    }
  }

  useEffect(() => {
    if (isActionsClicked) {
      document.addEventListener('click', closeDropdown)
    } else {
      document.removeEventListener('click', closeDropdown)
    }
    return () => {
      document.removeEventListener('click', closeDropdown)
    }
  }, [isActionsClicked])

  const handleCheckedAll = () => {
    const selectedItems = isAllChecked ? [] : search ? deletedList : deletedList

    setSelectedItems(selectedItems)
    setAllChecked(!isAllChecked)
  }

  const handleRestoreAction = async () => {
    const toolboxIds = selectedItems.map((item) => item.id)
    setAllChecked(false)
    try {
      await Api.postWithParams(
        'toolbox_talks/bulk_restore',
        {
          deleted: 'false',
        },
        {
          ids: isAllChecked ? 'all' : toolboxIds.join(','),
          search: search,
        },
      )
      setPage(1)
      setRefresh(true)
    } catch {
      Notification.danger('Failed to restore reports')
    }

    setRestoreClicked(false)
    setSelectedItems([])
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        minWidth: '50px',
        width: '50px',
        textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '22px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered ? (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          ) : null}
          {isActionsClicked ? (
            <div className="actions__dropdown">
              <div
                className="action__text"
                onClick={() => {
                  setRestoreClicked(true)
                  setActionsClicked(false)
                }}
              >
                Restore
              </div>
            </div>
          ) : null}
          {isRestoreClicked && selectedItems.length ? (
            <div className="restore__popup" ref={restorePopUpRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setRestoreClicked(false)
                }}
              />
              <div className="popup__title">Restore?</div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  handleRestoreAction()
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      className: 'topic',
      name: 'Topic',
      listLocation: 'app.toolboxes.deleted',
      type: 'topic__name',
      filterHeader: true,
      additionalStyles: {
        width: '299px',
        minWidth: '299px',
        paddingLeft: '10px',
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'originator',
      name: 'Originator',
      listLocation: 'app.toolboxes.deleted',
      type: 'user__first_name,user__last_name',
      filterHeader: true,
      additionalStyles: {
        width: '191px',
        paddingLeft: 0,
        minWidth: '191px',
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'company',
      name: 'Company',
      listLocation: 'app.toolboxes.deleted',
      type: 'user__company_name',
      filterHeader: true,
      additionalStyles: {
        width: '195px',
        paddingLeft: 0,
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'project-name',
      name: 'Project',
      listLocation: 'app.toolboxes.deleted',
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        width: '221px',
        minWidth: '221px',
        textAlign: 'left',
        p: { margin: 0 },
        padding: 0,
      },
    },
    {
      className: 'completed',
      name: 'Completed',
      listLocation: 'app.toolboxes.deleted',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        width: '95px',
        minWidth: '95px',
        textAlign: 'left',
        p: { margin: 0 },
        padding: 0,
      },
    },
  ]

  const handleChecked = (item) => {
    const selectedIds = selectedItems.map((el) => el.id)
    if (selectedIds.includes(item.id)) {
      setSelectedItems(selectedItems.filter((el) => el.id !== item.id))
      setAllChecked(false)
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
  }

  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(isRestoreClicked, isSafari)

  return (
    <section css={baseStyle}>
      <div className="search-base">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
          onClear={() => {
            setAllChecked(false)
          }}
        />
      </div>
      <div className="table__container">
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <TableBody
            handleChecked={handleChecked}
            selectedItems={selectedItems}
            deletedList={deletedList}
            isAllChecked={isAllChecked}
          />
        </table>
      </div>

      <div className="page-switcher-base">
        <PageSwitcher
          pageLength={20}
          listCount={count}
          currentPage={page}
          callback={setPage}
          updateUrl={false}
        />
      </div>
    </section>
  )
}

function getBaseStyle(isRestoreClicked, isSafari) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px',
    backgroundColor: 'white',
    paddingInline: '20px',
    '.search-base': {
      padding: '20px 0px',
    },
    table: {
      marginBottom: isRestoreClicked ? '68px' : '0',
    },
    td: {
      paddingRight: '10px',
      height: '40px',
    },
    '.static-nav': {
      borderBottom: '1px solid #C6C6C6',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '14px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      paddingInline: '3px',
      cursor: 'pointer',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.link__cell': {
      display: 'flex',
      gap: '15px',
      color: '#3980CE',
      cursor: 'pointer',
      alignItems: 'center',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.page-switcher-base': {
      marginBottom: '20px',
      marginLeft: '20px',
    },
    '.table__row': {
      color: '#575757',
      backgroundColor: '#fff',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox__td': {
      paddingLeft: '15px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.topic__td': {
      paddingLeft: '10px',
    },
    '.text__styles': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 0,
    },
    '.topic': {
      maxWidth: '299px',
    },
    '.name': {
      maxWidth: '191px',
    },
    '.company': {
      maxWidth: '195px',
    },
    '.project': {
      maxWidth: '221px',
    },
    '.restore__popup': {
      position: 'absolute',
      background: '#FFF',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
      zIndex: 1,
      width: '200px',
      left: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.popup__cross': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '#search': {
      width: '264px',
    },
    '.popup__title': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginTop: '20px',
      marginBottom: '11px',
      paddingInline: '20px',
    },
    '@media(max-width: 1370px)': {
      '.table__container': {
        overflow: 'scroll',
      },
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
    },
    '.topic__fullname': {
      left: '50px',
    },
    '.company__fullname': {
      left: '580px',
    },
    '.project__fullname': {
      left: '750px',
    },
    '@media(max-width: 1320px)': {
      '.full__name': {
        display: 'none',
      },
    },
    '@media(min-width: 1386px)': {
      width: '1125px',
    },
  })
}
