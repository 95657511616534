import * as React from 'react'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import activeDownloadIcon from '/src/assets/icons/svg-blue-icons/download-blue.svg'
import paperClip from '/src/assets/icons/Icons_Paperclip.svg'
import {
  ArchiveIcon,
  ShareIcon,
  DownloadIcon,
  EditIcon,
} from '@/react/componentAssets'

import { useAngularServices } from '@/react/components'
import { PopUp, VariousTable } from '@screens/SafetyDataSheets/components'
import { useRouter } from '@/react/hooks'
import { saveBase64AsFile } from '@/utils'

export function TableRow({ result, onVisibilityChange }: any) {
  const { uiPermissionService, Api, $rootScope } = useAngularServices()
  const { stateService } = useRouter()
  const [isRowVisible, setIsRowVisible] = React.useState(true)
  const [activePopup, setActivePopup] = React.useState(false)
  const [activeSharePopup, setActiveSharePopup] = React.useState(false)
  const adminStatus = uiPermissionService.getPermissionViewAdministration()

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const variableProjectsName = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].projects

  async function archiveSDS(id: string, note: string) {
    await Api.SDSArchiveAction(id, { archive: true, archive_notes: note || '' })
    setIsRowVisible(false)
    onVisibilityChange(result.id, false)
  }

  async function downloadFileInReport(idParam: string) {
    const { data } = await Api.getFileDataForDownload(idParam)
    saveBase64AsFile(data.content, data.filename, data.content_type)
  }

  let fileElement = (
    <td className={'subdownload centerIcons'}>
      <div css={centerTDItems}>
        <DownloadIcon height={'20px'} color={'gray'} />
      </div>
    </td>
  )
  let shareElement = (
    <div css={centerTDItems}>
      <ShareIcon height={'20px'} color={'gray'} />
    </div>
  )

  if (result.pdf) {
    fileElement = (
      <td className={'subdownload centerIcons'}>
        <div css={centerTDItems}>
          <DownloadIcon
            height={'20px'}
            color={variableColor}
            onClick={() => {
              downloadFileInReport(result.pdf.id)
            }}
          />
        </div>
      </td>
    )
    shareElement = (
      <div css={centerTDItems}>
        {activeSharePopup ? (
          <div css={sharePopUp} className={'sharePopUp'}>
            <input type="text" readOnly={true} value={result.pdf.file} />
            <div
              className={'copy-button fa fa-clipboard'}
              onClick={() => {
                navigator.clipboard.writeText(result.pdf.file)
              }}
            >
              <div className="clip-board-popup">
                Copied to clipboard
                <div className={'clip-board-arrow'} />
              </div>
            </div>
            <div className="arrow" />
          </div>
        ) : (
          ''
        )}
        <ShareIcon
          height={'20px'}
          color={variableColor}
          onClick={() => {
            setActiveSharePopup(!activeSharePopup)
          }}
        />
      </div>
    )
  }

  if (isRowVisible) {
    return (
      <tr key={result.id} id={result.id} css={rowHeader}>
        <td className={'submanufacturer'}>{result.manufacturer}</td>
        <td className={'subissue'}>{result.issue_date}</td>
        <td className={'subreview'}>{result.review_date}</td>
        <td className={'subhazard centerText'}>
          <VariousTable element={result.hazards} />
        </td>
        <td className={'subtoxic centerText'}>{result.toxic_chemical}</td>
        <td className={'subphysicalHazard centerText'}>
          <VariousTable element={result.whmis_physical_hazards} />
        </td>
        <td className={'subhealthHazard centerText'}>
          <VariousTable element={result.whmis_health_hazards} />
        </td>
        <td className={'subhealth centerText'}>
          {result.health ? result.health.charAt(0) : ''}
        </td>
        <td className={'subflammability centerText'}>
          {result.flammability ? result.flammability.charAt(0) : ''}
        </td>
        <td className={'subPH centerText'}>
          {result.physical_hazards ? result.physical_hazards.charAt(0) : ''}
        </td>
        <td className={'subPPE centerText'}>
          {result.ppe ? result.ppe.charAt(0) : ''}
        </td>
        {adminStatus ? (
          <td className={'subProjectsSelect'}>
            <div css={centerTDItems}>
              <UISref
                to="app.safety_data_sheets_project_assign"
                params={{ key: result.id }}
              >
                <a css={addProjectStyle}>
                  <EditIcon
                    height={'20px'}
                    width={'20px'}
                    color={variableColor}
                  />
                  <span css={css({ color: variableColor })}>
                    {result.projects.length}
                  </span>
                </a>
              </UISref>
            </div>
          </td>
        ) : null}
        {fileElement}
        <td className={'subshare'}>{shareElement}</td>
        {adminStatus ? (
          <td className={'subarchive'}>
            <div css={centerTDItems} onClick={() => setActivePopup(true)}>
              <ArchiveIcon height={'20px'} color={variableColor} />
            </div>
          </td>
        ) : null}
        <td>
          {activePopup ? (
            <PopUp
              functionToTrigger={archiveSDS}
              id={result.id}
              setState={setActivePopup}
              popUpText={
                '-This will affect ' +
                result.projects.length +
                ' ' +
                variableProjectsName
              }
              popUpHeader={'Are you sure you want to archive this SDS?'}
              popUpInputHeader={'Reason to Archive?'}
              activeInput
            />
          ) : (
            ''
          )}
        </td>
      </tr>
    )
  } else {
    return null
  }
}

function nameCropper(name: string) {
  if (name.length <= 30) return name
  const [firstWord, secondWord] = name.split(' ')
  if (!secondWord) return name.slice(30) + '...'

  const trimmedFirstLine =
    firstWord.length > 30 ? firstWord.slice(30) + '... ' : firstWord + ' '
  const trimmedSecondLine =
    secondWord.length > 30 ? secondWord.slice(30) + '...' : secondWord

  return (
    <span>
      {trimmedFirstLine}
      <br />
      {trimmedSecondLine}
    </span>
  )
}

const hiddenTable = css({
  display: 'none',
})
const rowHeader = css({
  borderTop: '1px solid rgb(229, 230, 231)',
  borderBottom: '1px solid rgb(229, 230, 231)',
  '.subnameColumn': {
    borderRightStyle: 'solid',
    borderColor: '#e7eaec',
  },
  '.centerText': {
    textAlign: 'center',
  },
})
const addProjectStyle = css({
  paddingLeft: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  height: '20px',
  span: {
    paddingLeft: '5px',
  },
})
const sharePopUp = css({
  border: '1px solid #ccc',
  borderRadius: '6px',
  position: 'absolute',
  right: '100%',
  top: '-75%',
  backgroundColor: 'white',
  width: '300px',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '9px',
  paddingBottom: '9px',
  paddingLeft: '14px',
  paddingRight: '14px',
  input: {
    width: '375px',
    height: '34px',
    color: 'rgb(103, 106, 108)',
    backgroundColor: 'rgb(255, 255, 255)',
    borderColor: 'rgb(229, 230, 231)',
    borderRadius: 0,
    borderStyle: 'solid',
    borderRightStyle: 'none',
    paddingBottom: '6px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '6px',
  },
  '.copy-button': {
    zIndex: 900,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '34px',
    minWidth: '34px',
    borderRadius: 0,
    borderColor: 'rgb(229, 230, 231)',
    borderStyle: 'solid',
    position: 'relative',
  },
  '.copy-button:hover': {
    backgroundColor: '#E7EAEC',
    '.clip-board-popup': {
      display: 'flex',
    },
  },
  '.arrow': {
    position: 'absolute',
    width: '15px',
    height: '15px',
    backgroundColor: 'white',
    transformOrigin: 'center',
    transform: 'rotate(45deg)',
    borderRight: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    top: 'calc(50% - 8px)',
    right: '-8px',
  },
  '.clip-board-popup': {
    paddingTop: '5px',
    display: 'none',
    justifyContent: 'center',
    whiteSpace: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: '130%',
    height: '35px',
    width: '70px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '12px',
    backgroundColor: 'rgba(0,0,0,0.75)',
    fontFamily: "'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    '.clip-board-arrow': {
      zIndex: 1000,
      position: 'absolute',
      width: '8px',
      height: '8px',
      background:
        'linear-gradient(140deg, rgba(15,28,41,0) 50%, rgba(0,0,0,0.7525603991596639) 50%)',
      transformOrigin: 'center',
      transform: 'rotate(45deg)',
      bottom: '-3px',
    },
  },
})
const centerTDItems = css({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})
const nameField = css({
  display: 'flex',
  alignItems: 'center',
  '.pin-place': {
    height: '20px',
  },
  '.active-pin': {
    width: '20px',
    backgroundImage: 'url(' + paperClip + ')',
  },
})
