import { useAngularServices } from '@/react/components'
import React, { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { CreateEditQuestionComponent } from '../CreateEditQuestionPage/CreateEditQuestionComponent'

export const ObservationEditQuestion = () => {
  const { stateService } = useRouter()
  const [startingData, setStartingData] = useState({})
  const { Api, CurrentUser, Notification } = useAngularServices()
  const [isMaxThresholdModal, setMaxThresholdModal] = useState(false)

  useEffect(() => {
    const getStartingData = async () => {
      const { data: categoriesRes } = await Api.get('categories', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        order: 'name',
        is_active: true,
        custom: true,
        page_size: 250,
      })

      const { data } = await Api.get(
        `questions/${stateService.params.question}`,
        {},
      )
      const selectedCategory = categoriesRes.results.find(
        (c) => c.id === data.category,
      )

      setStartingData({
        ...data,
        category: selectedCategory.name,
        editorContent: data?.name,
      })
    }
    getStartingData()
  }, [])

  const handleQuestionAdd = async (selectedCategory, editorContent) => {
    try {
      await Api.patch(`questions/${startingData.id}`, {
        category: selectedCategory?.id,
        client: CurrentUser.getClientId(),
        date_created: startingData?.date_created,
        date_updated: startingData?.date_updated,
        deleted: false,
        id: startingData?.id,
        is_active: true,
        name: editorContent,
        order: 0,
        source: 'internal',
      })

      stateService.go('app.questions.list', {
        categoryId: stateService.params.previousCategory,
        pageNumber: stateService.params.previousPageNumber,
        search: stateService.params.previousSearch,
      })
    } catch (error) {
      Notification.clearNotification()
      if (error.status === 400) {
        setMaxThresholdModal(error.data.detail)
      }
    }
  }

  return (
    <CreateEditQuestionComponent
      isMaxThresholdModal={isMaxThresholdModal}
      setMaxThresholdModal={setMaxThresholdModal}
      handleQuestionAdd={handleQuestionAdd}
      startingData={startingData}
    />
  )
}
