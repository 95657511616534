import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import viewIcon from '@/assets/icons/view-icon.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import uploadIcon from '@/assets/icons/upload-icon.svg'
import { useRouter, useSref } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import { useAngularServices } from '@/react/components'
import deleteIcon from '@/assets/icons/delete-nav-icon.svg'

// @ts-ignore
export const NavBar = ({}) => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const [isTabsVisable, setTabsVisable] = useState(false)

  useEffect(() => {
    setTabsVisable(
      CurrentUser.getRole() !== 'trainee' &&
        CurrentUser.getRole() !== 'client_user' &&
        CurrentUser.getRole() !== 'supervisor' &&
        CurrentUser.getRole() !== 'read_only',
    )
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'trainings.documentation'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'trainings.documentation' ||
                stateService.current.name === 'trainings.edit',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        {isTabsVisable && (
          <>
            <UISref to={'trainings.add'}>
              <a
                className={classNames('tab', {
                  active: stateService.current.name === 'trainings.add',
                })}
              >
                <img className="nav__icon" src={addIcon} alt="add nav__icon" />
                <p>Add</p>
              </a>
            </UISref>
            <UISref to={'trainings.import'}>
              <a
                className={classNames('tab', {
                  active: stateService.current.name === 'trainings.import',
                })}
              >
                <img
                  className="upload__nav__icon"
                  src={uploadIcon}
                  alt="add nav__icon"
                />
                <p>Upload</p>
              </a>
            </UISref>
          </>
        )}
        {CurrentUser.getRole() === 'client_admin' ||
        CurrentUser.getRole() === 'trainee_manager' ? (
          <UISref to={'trainings.deleted'}>
            <a
              className={classNames('tab', {
                active: stateService.current.name === 'trainings.deleted',
              })}
            >
              <img
                className="nav__icon"
                src={deleteIcon}
                alt="view nav__icon"
              />
              <p>Deleted</p>
            </a>
          </UISref>
        ) : null}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  marginLeft: '5px',
  display: 'flex',
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '25px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '25px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '25px',
  },
})
