import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { SelectWithSearch } from '../../components/SelectWithSearch'
import React, { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import {
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import './rich-text-editor.css'
import { registerLicense } from '@syncfusion/ej2-base'
import { QuestionNavBar } from '../components/QuestionsNavBar'
import { InfoModal } from '@/react/componentAssets/InfoModal'

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxed3RcRGZcVER1VkQ=',
)

export const CreateEditQuestionComponent = ({
  handleQuestionAdd,
  startingData,
  isMaxThresholdModal,
  setMaxThresholdModal,
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [editorContent, setEditorContent] = useState('')
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  useEffect(() => {
    if (startingData) {
      setEditorContent(startingData.editorContent)
      setCategory(startingData?.category)
    }
  }, [startingData])

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await Api.get('categories', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        is_active: true,
        custom: true,
        page_size: 250,
        order: 'name',
      })
      setCategories(data.results)
    }
    getCategories()
  }, [])

  const isEditorEmpty = (editorValue: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = editorValue

    const textContent = tempElement.textContent || tempElement.innerText

    return !textContent
  }

  const baseStyle = getBaseStyle(variableColor)

  const isCreate = stateService.current.name === 'app.questions.add'

  return (
    <section className="custom-questions" css={baseStyle}>
      <CommonPageHeader headerText="Questions" />
      <QuestionNavBar />
      <main className="page__wrapper">
        <div className="select__wrapper">
          <SelectWithSearch
            title="Custom Category"
            options={categories}
            value={category}
            setValue={setCategory}
            width="351px"
            mobileWidth="351px"
            resetFunction={() => {
              setButtonDisabled(false)
            }}
          />

          {isMaxThresholdModal ? (
            <InfoModal
              message={isMaxThresholdModal}
              setModalOpen={setMaxThresholdModal}
            />
          ) : null}
        </div>
        <div className="editor__wrapper">
          <h4 className="editor__title">Question</h4>
          <RichTextEditorComponent
            id="defaultRTE"
            value={editorContent}
            toolbarSettings={{
              items: ['CreateLink'],
            }}
            saveInterval={1}
            change={(e) => {
              setButtonDisabled(false)
              setEditorContent(e.value)
            }}
          >
            <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]} />
          </RichTextEditorComponent>
        </div>
        <div className="buttons__wrapper">
          {startingData ? (
            <ButtonElement
              text="Cancel"
              functionToTrigger={() => {
                stateService.go('app.questions.list', {
                  categoryId: stateService.params.previousCategory,
                  pageNumber: stateService.params.previousPageNumber,
                  search: stateService.params.previousSearch,
                })
              }}
            />
          ) : null}
          <ButtonElement
            text={isCreate ? 'Create' : 'Save'}
            buttonType="submit"
            functionToTrigger={() => {
              setButtonDisabled(true)
              const selectedCategory = categories.find(
                (c) => c.name === category,
              )
              handleQuestionAdd(selectedCategory, editorContent)
            }}
            disabled={
              !category || isEditorEmpty(editorContent) || isButtonDisabled
            }
          />
        </div>
      </main>
    </section>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    paddingBottom: '250px',
    '.page__wrapper': {
      background: '#fff',
      marginLeft: '5px',
      width: 'fit-content',
      paddingRight: '20px',
    },
    '.select__wrapper': {
      paddingLeft: '29px',
      paddingTop: '20px',
      paddingBottom: '20px',
      position: 'relative',
    },
    '.select__title': {
      fontSize: '14px',
      fontWeight: 600,
      color: '#68696D',
    },
    '.editor__wrapper': {
      width: '520px',
      paddingLeft: '30px',
      position: 'relative',
    },
    '.quill': {
      width: '550px',
    },
    '.ql-toolbar': {
      borderTop: 'none',
      borderInline: 'none',
    },
    '.ql-link': {
      position: 'absolute',
      left: '90px',
      top: 0,
    },
    '.editor__title': {
      position: 'absolute',
      fontSize: '14px',
      fontWeight: 600,
      color: '#68696D',
      zIndex: 1,
      margin: 0,
      top: '13px',
    },
    '.buttons__wrapper': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      paddingTop: '20px',
      paddingBottom: '16px',
      width: '520px',
    },
    '.e-richtexteditor': {
      border: 'none',
    },
    '#defaultRTE_toolbar': {
      position: 'static',
      background: '#fff',
      border: 'none !important',
    },
    '.e-create-link': {
      position: 'absolute',
      left: '70px',
      background: '#fff',
      color: `#3990CE !important`,
    },
    '.e-toolbar-items': {
      background: '#fff',
    },
    '.e-rte-content': {
      border: '1px solid #E5E6E7 !important',
    },
    '.e-input:focus': {
      borderBottom: `1px solid ${variableColor} !important`,
    },
    '.e-check': {
      backgroundColor: `${variableColor} !important`,
    },
    '.e-insertLink': {
      color: `${variableColor} !important`,
    },
    '.e-content': {
      padding: '5px !important',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    'button:disabled': {
      backgroundColor: 'gray !important',
      color: '#fff !important',
    },
    '@media(max-width: 500px)': {
      '.page__wrapper': {
        width: '100%',
        overflowX: 'scroll',
      },
    },
  })
