import { EditIcon } from '@/react/componentAssets'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const ProjectRow = ({ project, index, search, setRefresh }) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [hoveredField, setHoveredField] = useState(null)

  const [maxThresholdMessage, setMaxThresholdMessage] = useState(false)

  const { stateService } = useRouter()

  const handleProjectAssign = async () => {
    try {
      const { data } = await Api.postV2WithParams(
        `categories/${stateService.params.category}/assign_projects`,
        [
          {
            id: project?.id,
            active: !project?.active,
          },
        ],
        {
          application: stateService.params.app,
        },
      )
    } catch (error) {
      if (error.status === 400) {
        setMaxThresholdMessage(error.data.detail)
      }
    }
    setRefresh(true)
  }

  return (
    <tr
      className={classNames('project__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: project?.name,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={project?.name}
        />
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={project?.active}
            onChange={handleProjectAssign}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>

        {maxThresholdMessage ? (
          <InfoModal
            message={maxThresholdMessage}
            setModalOpen={setMaxThresholdMessage}
            left="-220px"
          />
        ) : null}
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
